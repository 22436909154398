import React, { Component } from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Twemoji from 'react-twemoji'


import { IntlProvider } from 'react-intl'

import { ThemeProvider } from 'styled-components'
import { light } from '../styles/theme'

import SEO from '../components/SEO'
import Page, { Content } from '../components/Page'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Wrapper from '../components/Wrapper'

import favicon from '../images/favicon.ico';

import styled, { keyframes } from 'styled-components'

const CircleKeyFrames = keyframes`
  0% { background: url(/img/landing-back-1.png); }
  5% { background: url(/img/landing-back-1.png); }
  10% { background: url(/img/landing-back-1.png); }
  15% { background: url(/img/landing-back-1.png); }
  20% { background: url(/img/landing-back-1.png); }
  25% { background: url(/img/landing-back-2.png); }
  30% { background: url(/img/landing-back-2.png); }
  35% { background: url(/img/landing-back-2.png); }
  40% { background: url(/img/landing-back-2.png); }
  45% { background: url(/img/landing-back-3.png); }
  50% { background: url(/img/landing-back-3.png); }
  55% { background: url(/img/landing-back-3.png); }
  60% { background: url(/img/landing-back-3.png); }
  65% { background: url(/img/landing-back-4.png); }
  70% { background: url(/img/landing-back-4.png); }
  75% { background: url(/img/landing-back-4.png); }
  80% { background: url(/img/landing-back-4.png); }
  85% { background: url(/img/landing-back-4.png); }
  90% { background: url(/img/landing-back-5.png); }
  91% { background: url(/img/landing-back-5.png); }
  100% { background: url(/img/landing-back-5.png);}
`

const Container = Content.extend`
  animation: ${CircleKeyFrames} 0.5s linear 0s 1;
  animation-delay: 1.5s;
`

class Layout extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { children, page } = this.props

    const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.siteDescription'
    )

    const defaultCover = siteUrl + get(this, 'props.data.defaultCover.childImageSharp.resolutions.src')
    const theme =  light
    
    const i18nMessages = {}
    const meta = {
      title: 'Dogan',
      decription: siteDescription,
      image: defaultCover,
      siteTitle: siteUrl  
    }

    if (page==="landing") {
      return (
        <IntlProvider defaultLocale="en" locale="en" messages={i18nMessages}>
          <ThemeProvider theme={theme}>
            <Twemoji>
              <Helmet
                title={siteTitle}
                meta={[
                    { name: 'description', content: '...' },
                    { name: 'keywords', content: '....' },
                ]}
                link={[
                    { rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` }
                ]}
              />
              <SEO {...meta} />
              <Page fontSize={[1, 1, 2]}>
                <Header />
                <Container>
                    <Wrapper>{children}</Wrapper>
                </Container>
                <Footer />
              </Page>
            </Twemoji>
          </ThemeProvider>
        </IntlProvider>
      ) 
    } else {
      return (
        <IntlProvider defaultLocale="en" locale="en" messages={i18nMessages}>
          <ThemeProvider theme={theme}>
            <Twemoji>
              <Helmet
                title={siteTitle}
                meta={[
                    { name: 'description', content: '...' },
                    { name: 'keywords', content: '....' },
                ]}
                link={[
                    { rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` }
                ]}
              />
              <SEO {...meta} />
              <Page fontSize={[1, 1, 2]}>
                <Header />
                <Content>
                    <Wrapper>{children}</Wrapper>
                </Content>
                <Footer />
              </Page>
            </Twemoji>
          </ThemeProvider>
        </IntlProvider>
      ) 
    }
  }
}

export default Layout
