import get from 'lodash/get'
import GatsbyLink from 'gatsby-link'
import { body } from '../../styles/typography'
import Box from '../Box'
import { themeGet } from 'styled-system'

const Link = Box.extend`
	${body}
	background: none;
	cursor: pointer;
	text-decoration: none;

	&.focus-visible, &:hover {
		text-decoration: none;
		color: ${themeGet('colors.contrast.3')};
	}

	& > svg {
		height: 1.125em;
		margin: 0 0.375em -0.125em;
		width: auto;
	}
`.withComponent(GatsbyLink)

Link.defaultProps = {
	border: 'none',
	color: 'contrast.4',
	display: 'inline-flex',
	p: 0
}

export const TextLink = Link.extend`
	transition: color .2s ease;

	&.focus-visible,
	&:hover {
		color: ${props => get(props.theme.colors, props.hoverColor, props.hoverColor)};
		text-decoration: none;
	}
`

TextLink.defaultProps = {
	display: 'inline-flex',
	hoverColor: 'primary'
}

export const ListLink = TextLink.extend`
	transition: color .2s ease, border .2s ease;

	&.focus-visible,
	&:hover {
		color: ${props => get(props.theme.colors, props.hoverColor, props.hoverColor)};
	}
`

ListLink.defaultProps = {
	alignSelf: 'flex-start',
	color: 'text',
	display: 'inline-flex',
	hoverColor: 'contrast.3'
}

export const PostLink = TextLink.extend`
	display: block !important;
	padding: 0.25em 0;
	transition: color .2s ease, border .2s ease;

	&.focus-visible,
	&:hover {
		color: ${props => get(props.theme.colors, props.hoverColor, props.hoverColor)};
	}
`

PostLink.defaultProps = {
	color: 'text',
	display: 'block',
	hoverColor: 'contrast.2'
}

export const RelateLink = TextLink.extend`
	transition: color .2s ease, border .2s ease;
	color: #AF9C82;
	border-bottom: 1px solid #AF9C82;
	font-size: 20px;
    line-height: 34px;
    width: fit-content;
	&.focus-visible,
	&:hover {
		color: ${props => get(props.theme.colors, props.hoverColor, props.hoverColor)};
	}
`

RelateLink.defaultProps = {
	alignSelf: 'flex-start',
	color: 'text',
	display: 'inline-flex',
	hoverColor: 'contrast.3'
}

export default Link
