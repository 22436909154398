import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

import Term from '../Term'
import Link from '../Link'
import Wrapper from '../Wrapper'

const FooterContainer = Wrapper.extend`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(7, auto);
  grid-column-gap: 1em;
  margin: 0.5em auto 0;
  position: relative;
`.withComponent('footer')

const FooterLogo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 2 / -1;
  grid-row: 5;
  & > h4 {
    color: ${themeGet('colors.text')};
    text-align: right;
    font-size: 1.5em;
    width: 100%;
    margin: 0px;
    margin-top: 5em;
  }
  @media (max-width: ${themeGet('breakpoints.4')}) {
    & > h4 {
      margin-top: 4.7em;
    }
  }
  @media (max-width: ${themeGet('breakpoints.2')}) {
    & > h4 {
      margin-top: 1em;
    }
  }
  @media (max-width: ${themeGet('breakpoints.0')}) {
    & > h4 {
      margin-top: 18em;
    }
  }
`

const FooterLink = Link.extend`
	clear: both;
	float: left;
  line-height: 1.25;
`

FooterLink.defaultProps = {
	border: 'none',
	fontSize: '18px',
	color: 'contrast.4',
	my: '0.5em',
	px: 0,
	py: 0
}

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 0 / -1;
  grid-row: 2 / -2;
  justify-content: space-between;
  padding: 0.5em 0 2em;
  width: 100%;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    justify-content: start;
    width: 50%;
    grid-column: 1 / -1;
  }

  @media (min-width: ${themeGet('breakpoints.2')}) {
    grid-column: 1 / -4;
    justify-content: space-between;
    padding: 0;
  }
`

const Links = styled.div`
  box-sizing: border-box;
  padding-right: 1em;

  & > h5 {
    color: ${themeGet('colors.text')};
    font-size: 0.75em;
    margin: 2em 0 0.5em 0;
  }

  @media (max-width: ${themeGet('breakpoints.0')}) {
    width: 100%;
  }

  @media (min-width: ${themeGet('breakpoints.0')}) {
    width: 33.3333%;
  }

  @media (min-width: ${themeGet('breakpoints.1')}) {
    width: auto;
  }

  @media (min-width: ${themeGet('breakpoints.2')}) {
    & > h5 {
      margin: 0.25em 0 0.5em;
    }
  }
`

  
class Footer extends PureComponent {
  render() {
    return (
      <FooterContainer px="1.5rem" pt={['64px', '64px', '128px']} pb="64px">
        <Navigation>
          <Links>
            <FooterLink to="/about"><Term>About</Term></FooterLink>
            <FooterLink to="/ventures"><Term>Ventures</Term></FooterLink>
            <FooterLink to="/articles"><Term>Articles</Term></FooterLink>
            <FooterLink to="/contact"><Term>Contact</Term></FooterLink>
          </Links>
          <Links>
						<FooterLink to="/notes"><Term>Notes</Term></FooterLink>
            <FooterLink to="/readings"><Term>Reading</Term></FooterLink>
            <FooterLink to="/photos"><Term>Photos</Term></FooterLink>
            <FooterLink to="/movies"><Term>Movies</Term></FooterLink>
          </Links>
          <Links>
            <FooterLink
              to="//twitter.com/skyborn"
              target="_blank"
              rel="nofollow"
            >
              Twitter
            </FooterLink>
            <FooterLink
              to="//linkedin.com/company/skyborn"
              target="_blank"
              rel="nofollow"
            >
              LinkedIn
            </FooterLink>
            <FooterLink
              to="//instagram.com/skyborn"
              target="_blank"
              rel="nofollow"
            >
              Instagram
            </FooterLink>
            <FooterLink
              to="//facebook.com/skyborn"
              target="_blank"
              rel="nofollow"
            >
              GitHub
            </FooterLink>
          </Links>
          <Links>
            <FooterLink to="/start"><Term>Start</Term></FooterLink>
            <FooterLink to="/start2"><Term>Start2</Term></FooterLink>
          </Links>
        </Navigation>
        <FooterLogo>
          <h4><Link to="/">dogan.</Link></h4>
        </FooterLogo>
      </FooterContainer>
    )
  }
}

export default Footer
