import Link from 'gatsby-link'
import { fontWeight, themeGet } from 'styled-system'

import Box from '../Box'
import { body } from '../../styles/typography'

const Button = Box.extend`
	${body}
	${fontWeight}
	border-radius: ${themeGet('radii.2')};
	line-height: 1;
	text-decoration: none;
`.withComponent('button')

Button.defaultProps = {
	bg: 'primary',
	border: 'none',
	color: 'white',
	display: 'inline-flex',
	fontWeight: 'bold',
	px: 4,
	py: 3
}

export const ButtonLink = Button.withComponent(Link)
export const ExternalButtonLink = Button.withComponent('a')

export default Button
