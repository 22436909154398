import React from 'react'
import { themeGet } from 'styled-system'
import kebabCase from 'lodash/kebabCase'
import styled from 'styled-components'
import { TextLink } from '../Link'
import Text from '../Text'
import PopupManager, { PopupContainer, PopupButton, PopupDialog } from './index'


const CategoryLink = TextLink.extend`
  & :hover {
    border-bottom: 2px solid #eee;
  }
`
CategoryLink.defaultProps = {
  color: 'text',
  display: 'flex',
  justifyContent: 'space-between',
  hoverColor: '#8D8D8F',
  my: 1,
  py: 1,
  fontWeight: 'bold'
}

const  Count = Text.extend.attrs({
	bg: 'contrast.0',
	color: 'contrast.3',
	fontSize: '10px',
	fontWeight: 'bold',
	lineHeight: 0,
	ml: 4,
	px: 2,
	py: '1px'
})`
	align-items: center;
	display: inline-flex;
	border-radius: ${themeGet('radii.3')};
`

const Triangle = styled.div`
  position: relative;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);

  & :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    margin-top: -5px;
    left: 30%;
    box-sizing: border-box;
    border: 0.5em solid;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 4px -2px rgba(0,0,0,0.1)
  }
`

export default class FilterCategory extends PopupManager {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

  render() {
    const { isVisible } = this.state
    const { categories, children } = this.props
    let total = 0

    return (
      <PopupContainer> Filter By &nbsp;
        <PopupButton bg="base" px={0} py={0} onClick={this.handleToggle}>
          {children}
        </PopupButton>
        <PopupDialog isVisible={isVisible} ml="85px">
          <Triangle />
          <CategoryLink to={'/articles'}>
            <Text >All</Text>
          </CategoryLink>
          {categories.map(category => {
            return (
              <CategoryLink
                key={category}
                to={`/articles/category/${kebabCase(category)}`}
              >
                <Text>{category}</Text>
              </CategoryLink>
            )
          })}
        </PopupDialog>
      </PopupContainer>
    )
  }
}

FilterCategory.defaultProps = {
  categories: []
}
