import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import camelCase from 'lodash/camelCase'
import { FormattedMessage } from 'react-intl'

export default class Term extends PureComponent {
	static propTypes = {
		id: PropTypes.string,
		children: PropTypes.string
	}

	render() {
		const { id, children } = this.props
		return <FormattedMessage id={id || camelCase(children)} defaultMessage={children} />
	}
}
