import { css } from 'styled-components'
import { themeGet } from 'styled-system'

import woffSupriaBlack from '../assets/font/supria/supria-black-webfont.woff'
import woff2SupriaBlack from '../assets/font/supria/supria-black-webfont.woff2'

import woffGraphikRegular from '../assets/font/graphik/Graphik-Regular-Web.woff'
import woff2GraphikRegular from '../assets/font/graphik/Graphik-Regular-Web.woff2'

import woffGraphikMedium from '../assets/font/graphik/Graphik-Medium-Web.woff'
import woff2GraphikMedium from '../assets/font/graphik/Graphik-Medium-Web.woff2'

import woffGraphikBlack from '../assets/font/graphik/Graphik-Black-Web.woff'
import woff2GraphikBlack from '../assets/font/graphik/Graphik-Black-Web.woff2'


export const logo = `
	font-family: "Supria", sans-serif;
	font-weight: 700;
	text-transform: uppercase;
`

export const body = `
	font-family: "Graphik", sans-serif;
`

export const subheading = `
	font-family: "Graphik", sans-serif;
	font-weight: bold;
	text-transform: uppercase;
`


export const link = css`
  border-bottom: ${themeGet('colors.contrast.0')} solid 2px;
  color: inherit;
  text-decoration: none;

  &:hover,
  &.focus-visible {
    border-bottom-color: ${themeGet('colors.primary')};
    transition: border-color 0.1s ease;
  }

  &:active {
    border-bottom-color: ${themeGet('colors.dark.2')};
  }
`

export const fontFaces = `
	@import url("//hello.myfonts.net/count/36949c");

	@font-face {
		font-display: swap;
		font-family: "Supria";
		font-weight: bold;
		src: url(${woffSupriaBlack}),
			url(${woff2SupriaBlack});
	}

	@font-face {
		font-display: swap;
		font-family: "Graphik";
		font-weight: normal;
		src: url(${woffGraphikRegular}),
			url(${woff2GraphikRegular});
	}

	@font-face {
		font-display: swap;
		font-family: "GraphikMedium";
		font-weight: bold;
		src: url(${woffGraphikMedium}),
			url(${woff2GraphikMedium});
	}
    
    @font-face {
		font-display: swap;
		font-family: "Graphik";
		font-weight: bold;
		src: url(${woffGraphikBlack}),
			url(${woff2GraphikBlack});
	}
`
