// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-readings-js": () => import("/opt/build/repo/src/templates/readings.js" /* webpackChunkName: "component---src-templates-readings-js" */),
  "component---src-templates-ventures-js": () => import("/opt/build/repo/src/templates/ventures.js" /* webpackChunkName: "component---src-templates-ventures-js" */),
  "component---src-templates-article-post-js": () => import("/opt/build/repo/src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-notes-post-js": () => import("/opt/build/repo/src/templates/notes-post.js" /* webpackChunkName: "component---src-templates-notes-post-js" */),
  "component---src-templates-start-2-js": () => import("/opt/build/repo/src/templates/start2.js" /* webpackChunkName: "component---src-templates-start-2-js" */),
  "component---src-templates-start-js": () => import("/opt/build/repo/src/templates/start.js" /* webpackChunkName: "component---src-templates-start-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-articles-mdx": () => import("/opt/build/repo/src/pages/articles.mdx" /* webpackChunkName: "component---src-pages-articles-mdx" */),
  "component---src-pages-movies-mdx": () => import("/opt/build/repo/src/pages/movies.mdx" /* webpackChunkName: "component---src-pages-movies-mdx" */),
  "component---src-pages-notes-mdx": () => import("/opt/build/repo/src/pages/notes.mdx" /* webpackChunkName: "component---src-pages-notes-mdx" */),
  "component---src-pages-photos-mdx": () => import("/opt/build/repo/src/pages/photos.mdx" /* webpackChunkName: "component---src-pages-photos-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

