import styled from 'styled-components'
import {
	alignItems,
	alignSelf,
	background,
	borders,
	borderColor,
	borderRadius,
	boxShadow,
	color,
	display,
	flex,
	flexDirection,
	flexWrap,
	fontSize,
	height,
	justifyContent,
	lineHeight,
	maxHeight,
	maxWidth,
	minHeight,
	minWidth,
	overflow,
	position,
	space,
	textAlign,
	width,
	zIndex,

	top,
	right,
	bottom,
	left

} from 'styled-system'

const Box = styled.div`
	${alignItems}
	${alignSelf}
	${background}
	${borders}
	${borderColor}
	${borderRadius}
	${boxShadow}
	${color}
	${display}
	${flex}
	${flexDirection}
	${flexWrap}
	${fontSize}
	${height}
	${justifyContent}
	${lineHeight}
	${maxHeight}
	${maxWidth}
	${minHeight}
	${minWidth}
	${overflow}
	${position}
	${space}
	${textAlign}
	${width}
	${zIndex}

	${top}
	${right}
	${bottom}
	${left}

	box-sizing: border-box;
`

export default Box
