import styled from 'styled-components'
import {
	color,
	display,
	fontSize,
	fontWeight,
	lineHeight,
	maxWidth,
	space,
	textAlign,
	width,
	marginTop,
	marginBottom,
} from 'styled-system'

const Text = styled.div`
	${color}
	${display}
	${fontSize}
	${fontWeight}
	${lineHeight}
	${maxWidth}
	${space}
	${textAlign}
	${width}
	${marginTop}
	${marginBottom}
`

export default Text
