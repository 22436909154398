import get from 'lodash/get'
import Box from '../Box'

const Wrapper = Box.extend`
  max-width: ${props => get(props.theme.wrapper, props.maxWidth, props.maxWidth)};
`

Wrapper.defaultProps = {
	maxWidth: 'normal',
	mx: 'auto',
	px: '1.5rem',
	width: '100%'
}

export const FullWidth = Wrapper.extend``

FullWidth.defaultProps = {
	maxWidth: 'none',
	mx: 'calc(-50vw + 50%)',
	px: 0,
	width: '100vw'
}

export const FullWidthOnMobile = FullWidth.extend``

FullWidthOnMobile.defaultProps = {
	maxWidth: ['none', 'none', 'normal'],
	mx: ['calc(-50vw + 50%)', 'calc(-50vw + 50%)', 'auto'],
	width: ['100vw', '100vw', '100%']
}

export default Wrapper