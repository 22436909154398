import get from 'lodash/get'
import GatsbyLink from 'gatsby-link'
import { body } from '../../styles/typography'
import Box from '../Box'
import { themeGet } from 'styled-system'

export const Post = Box.extend`
	width: ${props => props.width};
	margin-top: ${props => props.marginTop};
`
Post.defaultProps = {
	width: "100%",
	marginTop: "1.6em"
}

export const PostHeader = Box.extend`
	font-family: "GraphikMedium";
	margin-bottom: 0.6em;
	width: 100% !important;
	font-size: ${props => props.fontSize};
	@media (max-width: ${themeGet('breakpoints.2')}) { 
	    font-size: 1.47em;
	}
`

PostHeader.defaultProps = {
	fontSize: '1.47em'
}

export const PostDescription = Box.extend`
	width: 100% !important;
	font-size: ${props => props.fontSize};
	line-height: ${props => props.lineHeight};
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		font-size: 1.2em;
		lineHeight: '28px'
	}
`

PostDescription.defaultProps = {
	fontSize: '1.2em', 
	lineHeight: '28px'
}

export const PostDate = Box.extend`
	color: #8D8D8F;
	margin: 1em 0;
	font-size: ${props => props.fontSize};
	line-height: ${props => props.lineHeight};
`

PostDate.defaultProps = {
	fontSize: '0.94em', 
	lineHeight: '17px'
}

export const Thumbnail = Box.extend`
	width: ${props => props.width};
	height: ${props => props.height};
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		height: 192px;
	}
`

Thumbnail.defaultProps = {
	height: "192px",
	width: "100%"
}