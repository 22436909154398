import React from "react"
import Hero from "../components/Hero"
import Layout from "../layouts"
import { graphql, StaticQuery } from 'gatsby'

import ArticleList from './article-list'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
	0% {
		transform: translate(0, 100px);
		opacity:0;
	}
	100% {
		transform: translate(0, 0);
		opacity:1;
	}
`	

const List = styled.div`
	opacity: 0;
	animation: ${fadeIn} ease 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards; 
`

class Articles extends React.Component {
	constructor(props) {
		super(props)
	}

	renderList() {
		return(<StaticQuery
		    query={graphql`
			    query {
					allMdx(
						sort: { order: DESC, fields: [frontmatter___date] },
						filter: {frontmatter: {templateKey: {eq: "article-post" }}}){
						edges {
							node {
								id
								fields {
									slug
								}
								frontmatter {
									title
									description
									date(formatString: "MMMM DD - YYYY")
									image
									tags
									categories 
								}
							}
						}
					}
				}
		    `}
		    render={(data) => (
		    	<ArticleList data={data} />
		    )}
		  />)
	}
	
	render() {
		const {data} = this.props;
		
		return (
			<Layout>
		 		<Hero data={data} />
		 		<List>
		 			{this.renderList()}
		 		</List>
		  	</Layout>
		)
	}
}

export default Articles

