
import React from "react"
import { themeGet } from 'styled-system'
import styled from 'styled-components'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const _ = require('lodash')

const Category = styled.div`
	font-size: 36px;
	font-family: "GraphikMedium";
	margin: 100px 0 50px;
	text-transform: capitalize;
`

const Card = styled.div`
	margin: 10px;
	height: 750px;

	@media (max-width: ${themeGet('breakpoints.4')}) { 
		height: 390px;
	}
	
`
const Carousel = styled.div`
	width: 100vw;
	height: 800px;
	margin-left: calc((1088px - 100vw)/2);
	@media (max-width: ${themeGet('breakpoints.4')}) { 
		height: 450px;
	}
	@media (max-width: 1039px) { 
		margin: -1.6em;
	}
	
`
const Prev = styled.div`
	z-index: 100;
`

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "25px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Prev
      className={className}
      style={{ ...style, display: "block", left: "25px"}}
      onClick={onClick}
    />
  );
}

class PhotoList extends React.Component {
	renderSublist(category) {
		const { edges: notes } = this.props.data.allMdx ;
		return notes.map((note, i) => {
			if (note.node.frontmatter.categories.indexOf(category) > -1){
				return note.node.frontmatter.images.map((image, i)=> {
					return (
						<div>
							<Card key={i} style={{ background: `url(${image}) center top`, backgroundSize: 'cover'}} />
						</div>	
					)
				})
			}
	    })
	}

	renderList() {
		
		const { edges:notes } = this.props.data.allMdx;
	    let categories = []
	    notes.forEach(edge => {
	      if (_.get(edge, `node.frontmatter.categories`)) {
	        categories = categories.concat(edge.node.frontmatter.categories)
	      }
	    })
	   
	    categories = _.uniq(categories)

	    const amsterdam = {
			className: "center",
			centerMode: true,
			centerPadding: "150px",
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			lazyLoad: true,
			slidesToScroll: 1,
			nextArrow: <SampleNextArrow />,
      		prevArrow: <SamplePrevArrow />,
      		responsive: [
      			{
		          breakpoint: 800,
		          settings: {
		            slidesToShow: 1,
		            slidesToScroll: 1,
		            centerPadding: "100px",
		          }
		        },
		        {
		          breakpoint: 600,
		          settings: {
		            slidesToShow: 1,
		            slidesToScroll: 1,
		            centerPadding: "0px",
		          }
		        }
		    ]
		};

		const marrakesh = {
			className: "center",
			centerMode: true,
			centerPadding: "150px",
			infinite: true,
			speed: 500,
			slidesToShow: 2,
			lazyLoad: true,
			slidesToScroll: 1,
			nextArrow: <SampleNextArrow />,
      		prevArrow: <SamplePrevArrow />,
      		responsive: [
      			{
		          breakpoint: 800,
		          settings: {
		            slidesToShow: 2,
		            slidesToScroll: 1,
		            centerPadding: "0px",
		          }
		        },
		        {
		          breakpoint: 600,
		          settings: {
		            slidesToShow: 1,
		            slidesToScroll: 1,
		            centerPadding: "0px",
		          }
		        }
		    ]
		};

        return categories.map((item, i) => {
        	if(item==="marrakesh"){
        		return(
					<div key={i}>
						<Category>{item}</Category>
						<Carousel>
							<Slider ref={c => (this.slider = c)} {...marrakesh}>
					            {this.renderSublist(item)}
					        </Slider>
						</Carousel>
					</div>
				)
        	} else {
        		return(
					<div key={i}>
						<Category>{item}</Category>
						<Carousel>
							<Slider ref={c => (this.slider = c)} {...amsterdam}>
					            {this.renderSublist(item)}
					        </Slider>
						</Carousel>
					</div>
				)
        	}
			
	    })
	}
	
	render() {
		return (
			<div>
				{this.renderList()}
			</div>
		)
	}
}

export default PhotoList