
import React from "react"
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import {ListLink} from "../components/Link"
import Text from "../components/Text"
const _ = require('lodash')

const SubList = styled.div`
	align-items: flex-start;
	display: grid;
	grid-column: 1 / -1;
	grid-template-columns: repeat(3, 1fr);
	position: relative;
	border-bottom: 1px solid rgba(51,51,51,0.10);
	padding: 1em 0;	
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		grid-column-gap: 2em;
		grid-template-columns: repeat(1, 1fr);
	}
	
`

const TitleList = styled.div`
	grid-column: 2 / -1;
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		grid-column: 1 / 1;
		padding: 1em 0;
	}
`
const Dot = styled.span`
	color: #AF9C82;
	font-size: 40px;
	line-height: 0;
	vertical-align: middle;
	padding-right: 5px;
`

const Title = styled.span`
	vertical-align: middle;
	font-size: ${themeGet('fontSizes.3')};
	line-height: 50px;
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		margin-top: 1em;
		line-height: 40px;
	}
`

const NoteCategory = styled.div`
	font-size: 17px;
	line-height: 50px;
	font-family: "GraphikMedium";
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		margin-top: 1em;
		line-height: 0px;
	}
`

class NotesList extends React.Component {

	renderSublist(category) {
		const { edges: notes } = this.props.data.allMdx ;
		return notes.map((note, i) => {
			if (note.node.frontmatter.categories.indexOf(category) > -1){
				return (
					<span key={i}>
						<Dot>&middot;</Dot>
						<Title><ListLink to={note.node.fields.slug}>{note.node.frontmatter.title}</ListLink></Title>
						&nbsp;&nbsp;&nbsp;
					</span>
				)
			}
	    })
	}

	renderList() {
		
		const { edges:notes } = this.props.data.allMdx;
	    let categories = []
	    notes.forEach(edge => {
	      if (_.get(edge, `node.frontmatter.categories`)) {
	        categories = categories.concat(edge.node.frontmatter.categories)
	      }
	    })
	   
	    categories = _.uniq(categories)

        return categories.map((item, i) => {
			return(
				<SubList key={i}>
					<NoteCategory>{item}</NoteCategory>
					<TitleList>
						{this.renderSublist(item)}
					</TitleList>
				</SubList>
			)
			
	    })
		
	}
	
	render() {
		return (
			<div>
				{this.renderList()}
			</div>
		)
	}
}

export default NotesList