import React, { Component } from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'
import Box from '../Box'
import Button from '../Button'

export const PopupContainer = styled(Box)``
PopupContainer.defaultProps = {
	position: 'relative'
}

export const PopupButton = styled(Button)``
PopupButton.defaultProps = {
	bg: 'transparent',
	fontWeight: 'normal',
	position: 'relative',
	px: 0,
	py: 0
}

export const PopupDialog = styled(Box)`
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.0312),
							0 1px 3px rgba(0, 0, 0, 0.0312),
							0 3px 9px rgba(0, 0, 0, 0.0625),
							0 10px 30px rgba(0, 0, 0, 0.125);
	opacity: ${props => props.isVisible ? 1 : 0};
	pointer-events: ${props => props.isVisible ? 'initial' : 'none'};
	transform: ${props => props.isVisible ? 'none' : 'translateY(-1em)'};
	transition: opacity .2s ease,
							transform .2s ease;
	z-index: 2;
`

PopupDialog.defaultProps = {
	bg: 'light.1',
	borderRadius: 2,
	position: 'absolute',
	px: 3,
	py: 2,
	mt: 3,
	ml: -3
}

export default class PopupManager extends Component {
	constructor(props) {
		super(props)
		this.handleToggle = this.handleToggle.bind(this)
		this.state = {
			isVisible: false
		}
	}

	handleToggle() {
		const nextState = !this.state.isVisible

		if (nextState) {
			document.querySelector('body').onclick = this.handleToggle
		} else {
			document.querySelector('body').onclick = noop
		}

		this.setState({
			isVisible: nextState
		})
	}
}
