import styled from 'styled-components'
import globalStyles from '../../styles/global'
import { fontSize, themeGet } from 'styled-system'

const Page = styled.div`
	${fontSize}
	background: ${themeGet('colors.base')};
	color: ${themeGet('colors.text')};
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`

export const Content = styled.main`
	display: flex;
	flex: 1;
	flex-direction: column;
`

export default Page
