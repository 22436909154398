import { darken, lighten } from 'polished'
import assign from 'lodash/assign'

const deriveColors = base => {
    return {
        dark: [
            darken(0.025, base),
            darken(0.05, base),
            darken(0.075, base),
            darken(0.1, base),
            darken(0.125, base)
        ],
        light: [
            lighten(0.025, base),
            lighten(0.05, base),
            lighten(0.075, base),
            lighten(0.1, base),
            lighten(0.125, base)
        ]
    }
}

const variables = {
    baseFontSize: '15px'
}

const defaults = {
    white: '#fff',
    black: '#111',
    primary: '#2d8eff',
    secondary: '#43bf4d',
    selection: '#ffd700'
}

const contrast = ['#eee', '#ccc', '#999', '#666', '#333']

const lightColors = {
    ...defaults,
    ...deriveColors('#fff'),
    base: '#fff',
    text: '#111',
    contrast: contrast
}

const darkColors = {
    ...defaults,
    ...deriveColors('#111'),
    base: '#111',
    text: '#fff',
    contrast: contrast.slice(0).reverse()
}

const breakpoints = ['480px', '662px', '768px', '1024px', '1440px', '1680px', '1920px', '2560px']

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const fontSizes = ['0.75em', '0.933em', '1em', '1.125em', '1.2em', '1.6em', '2em', '2.4em', '2.625em', '3em', '3.5em', '5em', '10em']

const headings = ['0.75em', '1em', '1.25em', '1.5em', '2.625em', '3em', '4.7em', '5em', '8em', '10em']

const lineHeights = [1, 1.25, 1.5, 2, 2.5]

const fontWeights = {
    normal: 400,
    bold: 600
}

const letterSpacings = {
    normal: 'normal',
    caps: '0.125em'
}

const radii = ['0', '3px', '5px', '1em', '40%', '50%']

const borderWidths = [0, 1, 2]

const shadows = [
    '0 1px 0 0 rgba(0, 0, 0, 0.05)',
    '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
    '0 1px 4px 0 rgba(0, 0, 0, 0.1)'
]

const wrapper = {
    thin: '544px',
    news: '704px',
    narrow: '864px',
    normal: '1152px',
    wide: '1296px'
}

const theme = {
    ...variables,
    borderWidths,
    breakpoints,
    fontSizes,
    fontWeights,
    headings,
    letterSpacings,
    lineHeights,
    radii,
    shadows,
    space,
    wrapper
}

export const light = assign(
    {
        name: 'light',
        colors: lightColors,
        mapStyle: 'mapbox://styles/nelonoel/cjm4x78xx2aew2rpb3xypiouq'
    },
    theme
)

export const dark = assign(
    {
        name: 'dark',
        colors: darkColors,
        mapStyle: 'mapbox://styles/nelonoel/cjm4ysifybg4e2rpbm0palcm2'
    },
    theme
)
