import React from 'react'
import { themeGet } from 'styled-system'
import Wrapper from '../Wrapper'
import Link from '../Link'
import noop from 'lodash/noop'
import Box from '../Box'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
`	

const HeaderContainer = Wrapper.extend`
	align-items: flex-start;
	display: grid;
	grid-template-columns: repeat(2, auto);
	grid-template-rows: repeat(7, auto);
	grid-column-gap: 1em;
	margin: 0.8em auto;
	position: relative;
	visibility: visible;
	opacity: 0;
	animation: ${fadeIn} ease 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards; 
`.withComponent('header')


const MenuList = styled.div`
	align-items: flex-start;
	display: grid;
	grid-template-columns: repeat(4, auto);
	margin: 1.5em 0;
	font-size: 18px;

	& a {
		justify-content: flex-end;
	}
	@media (max-width: ${themeGet('breakpoints.1')}) {
		font-size: 1em;
		margin: 1em 0;
	}
	@media (max-width: ${themeGet('breakpoints.0')}) {
		display: none;
	}
`
const MenuItem = styled.div`
	text-align: right;
`
const ToggleButton = styled.button`
	display: none;
	margin: auto;
	margin-right: 0;
	padding: 0;
	@media (max-width: ${themeGet('breakpoints.0')}) {
		display: block;
	}
	& span {
		width: 20px;
		display: block;
		margin: 5px auto;
		margin-right: 0;
		border: 1px solid #333;
	}	
`

export const ToggleMenu = styled(Box)`
	opacity: ${props => props.isVisible ? 1 : 0};
	position: ${props => props.isVisible ? 'static' : 'absolute'};
	pointer-events: ${props => props.isVisible ? 'initial' : 'none'};
	transform: ${props => props.isVisible ? 'none' : 'translateY(-1em)'};
	transition: transform 0.2s ease;
	z-index: 2;
	text-align: right;
	grid-column: 2 / -1;
	min-height: 100vh;
	width: 100vw;

	& a {
		display: block;
		font-size: 20px;
		font-weight: bold;
		margin: 30px 0;
	}
`

ToggleMenu.defaultProps = {
	bg: 'light.1',
	borderRadius: 2,
	position: 'absolute',
	opacity: 0,
	px: "1.5em",
	py: 2,
	ml: "-59vw",
	mr: "-32.5vw"
}

const Logo = styled.div`
	box-sizing: border-box;
	padding-right: 1em;

	& > h4 {
		color: ${themeGet('colors.text')};
		font-size: 1.5em;
		margin: 1em 0;
	}

	@media (max-width: ${themeGet('breakpoints.1')}) {
		width: 33.33%;
		& > h4 {
		 	margin: 0.5em 0;
		}
	}

	@media (max-width: ${themeGet('breakpoints.0')}) {
		width: 50%;
	}
`

class Header extends React.Component {
	constructor(props) {
		super(props)
		this.handleToggle = this.handleToggle.bind(this)
		this.state = {
			isVisible: false
		}
	}
	handleToggle(event) {
		event.stopPropagation();
		const nextState = !this.state.isVisible

		if (nextState) {
			document.querySelector('body').onclick = this.handleToggle
		} else {
			document.querySelector('body').onclick = noop
		}

		this.setState({
			isVisible: nextState
		})
	}
	render() {

	    return (
			<HeaderContainer >
				<Logo>
					<h4><Link to="/">dogan.</Link></h4>
				</Logo>
				<MenuList>
					<MenuItem>
						<Link to="/about">About</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/ventures">Ventures</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/articles">Articles</Link>
					</MenuItem>
					<MenuItem>
						<Link to="/contact">Contact</Link>
					</MenuItem>
				</MenuList>
				<ToggleButton onClick={event=>this.handleToggle(event)}>
					<span></span>
					<span></span>
					<span></span>
				</ToggleButton>
				<ToggleMenu isVisible={this.state.isVisible}>
					<Link to="/about">About</Link>
					<Link to="/ventures">Ventures</Link>
					<Link to="/articles">Articles</Link>
					<Link to="/contact">Contact</Link>
					<Link to="/notes">Notes</Link>
					<Link to="/readings">Readings</Link>
					<Link to="/photos">Photos</Link>
					<Link to="/movies">Movies</Link>
				</ToggleMenu>
			</HeaderContainer>	
	    )
	}
}

export default Header