import React from "react"

import { themeGet } from 'styled-system'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
	0% {
		transform: translate(0, 20px);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
`
const HeroHeader = styled.h1`
	font-size: ${themeGet('headings.9')};
	margin-bottom: 0;
	
	animation: ${fadeIn} ease 1s;

	@media (max-width: ${themeGet('breakpoints.4')}) {
		font-size: ${themeGet('headings.8')};
	}
	@media (max-width: ${themeGet('breakpoints.2')}) {
		font-size: ${themeGet('headings.6')};
	}
	@media (max-width: ${themeGet('breakpoints.1')}) {
		margin: 0.5em 0;
	}

`

const HeroDescription = styled.p`
	font-size: ${themeGet('fontSizes.7')};
	padding-left: 20%;
	opacity: 0;
	
	animation: ${fadeIn} ease 1s;
	animation-delay: 0.75s;
	animation-fill-mode: forwards; 

	@media (max-width: ${themeGet('breakpoints.4')}) {
		font-size: ${themeGet('fontSizes.6')};
	}
	@media (max-width: ${themeGet('breakpoints.2')}) {
		font-size: ${themeGet('fontSizes.5')};
	}
	@media (max-width: ${themeGet('breakpoints.1')}) {
		padding-left: 0;
	}
`

class Hero extends React.Component {
	render() {
		const { data } = this.props
		return(
			<div>
				<HeroHeader>{data.title}</HeroHeader>
				<HeroDescription>{data.description}</HeroDescription>
			</div>
		)
	}
}

export default Hero